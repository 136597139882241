import actions from './actions'

const facilitySaleItem = {
  state: {
    statusUpdateFacilitySaleItem: false,
    saleItem: {
      id: null,
      name: '',
      price: 0,
      allowPriceOverwrite: false,
      forSale: true,
      priceIncludeTax: false,
    }
  },
  getters: {
    getStatusUpdateFacilitySaleItem (state) {
      return state.statusUpdateFacilitySaleItem
    }
  },
  mutations: {
    setSaleItem (state, saleItem) {
      state.saleItem.id = saleItem.id
      state.saleItem.name = saleItem.name
      state.saleItem.price = saleItem.price
      state.saleItem.allowPriceOverwrite = saleItem.allowPriceOverwrite
      state.saleItem.priceIncludeTax = saleItem.priceIncludeTax
    },
    resetSaleItem (state) {
      state.saleItem.id = null
      state.saleItem.name = ''
      state.saleItem.price = 0
      state.saleItem.allowPriceOverwrite = false
      state.saleItem.priceIncludeTax = false
    },
    setStatusUpdateFacilitySaleItem (state, payload) {
      state.statusUpdateFacilitySaleItem = !state.statusUpdateFacilitySaleItem
    }
  },
  actions
}

export default facilitySaleItem

export default {
  messages: {
    successfulNewCreation: '作成しました。',
    newCreationFailed: '新規作成に失敗しました。',
    successfulUpdate: '更新しました。',
    deletedSuccessfully: '削除しました。',
    deletedError: '削除できませんでした。',
    tokenExpires: 'トークンの有効期限',
    updateError: '更新失敗',
    createError: '新しいものを作成できません',
    duplicateDates: '祝日の日付が重複しています'
  },
  auth: {
    login: 'ログイン',
    username: 'ユーザー名',
    password: 'パスワード',
    email: 'メール',
    confirmation: '確認',
    code: 'コード',
    forgotPassword: 'パスワードをお忘れですか？',
    confirmationResetPassword: 'パスワード再設定の確認',
    resettingPassword: 'パスワードの再設定'
  },
  sidebar: {
    calendar: 'カレンダー',
    booking: '予約',
    feesPoints: '料金・ポイント・プラン',
    invitationUseTickets: '招待券・利用券',
    externalCooperation: '外部連携',
    report: 'レポート',
    facility: '施設',
    printingForm: '帳票印刷',
    membersPage: '会員ページ',
    users: 'ユーザー',
    logout: 'ログアウト',
    reservation: '予約リスト',
    guestRoomDailyReport: '客室パネル/日報',
    frameSetting: '枠設定',
    formReport: '帳票レポート',
    webPageManagement: 'WEBページ管理'
  },
  routeTitles: {
    dashboard: 'ダッシュボード',
    calendar: {
      calendar: 'カレンダー',
      booking: '予約',
      bookingCalendar: '予約カレンダー',
      inOutManagement: 'インアウト管理',
      roomAssignment: '部屋割り',
      roomAllocationCalendar: '部屋割りカレンダー',
      keys: '鍵',
      keyCalendar: '鍵カレンダー',
      carPark: '駐車場',
      carParkCalendar: '駐車場カレンダー',
      exhibitsForHire: '貸出品',
      rentalCalendar: '貸出品カレンダー',
      frameSettings: '枠設定'
    },
    booking: {
      booking: '予約',
      bookingList: '予約リスト',
      cancellationWait: 'キャンセル待',
      rci: 'RCI',
      guests: '宿泊客'
    },
    feesPoints: {
      feesPoints: '料金・ポイント・プラン',
      plans: 'プラン',
      feesAndPoints: '料金・ポイント',
      CSVUpdate: 'CSV更新'
    },
    invitationUseTickets: {
      invitationUseTickets: '招待券・利用券',
      list: 'リスト'
    },
    externalCooperation: {
      externalCooperation: '外部連携',
      roomTypes: '部屋タイプ',
      overallSettings: '全体設定'
    },
    formReport: {
      formReport: '帳票レポート',
      forIncomeReport: '収入報告書用',
      salesManagementTable: '営業管理表',
      otherCSV: 'その他CSV',
      monthlyLodgingTaxSchedule: '宿泊税月計表'
    },
    facility: {
      facility: '施設',
      facilitySettings: '施設設定',
      rentalList: '貸出品',
      productsForSale: '販売品',
      bookingRemarkByContract: '施設契約別予約備考'
    },
    printingForm: {
      printingForm: '帳票印刷',
      form: '帳票',
      taxSchedule: '宿泊税月計表'
    },
    membersPage: {
      membersPage: '会員ページ',
      informationRequest: '資料請求',
      bookVisit: '来店予約',
      onlineConsultation: 'オンライン相談',
      notices: 'お知らせ',
      experienceAccommodation: '体験宿泊',
      facilitySettings: '施設設定',
      webPageManagement: 'WEBページ管理(CRM共通)'
    },
    users: {
      users: 'ユーザー',
      information: '情報',
      userList: 'ユーザーリスト',
      userRights: 'ユーザー権限',
      usersCrmCommon: 'ユーザー(CRM共通)'
    },
    reservation: {
      reservation: '予約',
      reservationList: '予約リスト',
      sendUsageCertificate: '利用証等送付',
      cancellationApplied: 'キャンセル適用'
    },
    guestRoomDailyReport: {
      guestRoomDailyReport: '客室パネル/日報',
      guestRoomPanel: '客室パネル',
      dailyReport: '日報',
      dailyReportList: '日報一覧',
      holidaySetting: '祝日設定'
    },
    frameSetting: {
      frameSettingCalendar: '枠設定カレンダー',
      systemOuterLimitSetting: 'システム外枠設定'
    },
    pageNotFound: 'ページが見つかりません',
    invitationsAndCoupons: '招待券・利用券'
  },
  common: {
    totalQuantity: '総数量',
    all: '全て',
    display: '表示',
    inDevelopment: '開発中',
    title: '名称',
    search: '探',
    status: 'ステータス',
    contract: '契約',
    facility: '施設',
    roomType: '部屋タイプ',
    room: '部屋',
    roomNumber: '号室',
    checkIn: 'チェックイン',
    checkOut: 'チェックアウト',
    numOfDays: '日数',
    representative: '代表者',
    adult: '大人',
    children: '子供',
    email: 'Email',
    cases: '件のうち',
    showing: '件を表示',
    noResults: '結果がありません',
    toDay: '今日',
    kindName: '今金種名日',
    fileDownload: 'ファイルダウンロード',
    notIn: '未イン',
    notOut: '未アウト',
    unpaid: '未精算',
    member: '会員',
    bedSharing: '添寝',
    large: '大',
    small: '小',
    loan: '貸',
    block: 'ブロック',
    confirmed: '確認済み',
    none: 'なし',
    memberName: '会員名',
    group: 'グループ',
    remark: '備考',
    petInfo: 'ペット情報',
    city: '都市',
    country: '国',
    amountOfMoney: '金額',
    numberOfSheets: '枚数',
    close: '閉じる',
    save: '保存',
    notices: '特記事項',
    dateOfOccurrence: '発生日',
    unitPrice: '単価',
    startOfDeadline: '期限開始',
    endOfDeadline: '期限終了',
    subjectName: '科目名称',
    unit: '単位',
    points: 'ポイント',
    quantity: '数量',
    order: '個数',
    VAT: '消費税',
    salesTaxRate: '消費税率',
    subtotal: '小計',
    total: '合計',
    night: '泊目',
    cash: '現金',
    settlementMethod: '精算方法',
    cardType: 'カード種類',
    unionPay: '銀聯',
    membershipRegisteredCard: '会員登録済みカード',
    beforeChange: '変更前',
    proviso: '但し書き',
    provisoCut: '但',
    fullName: '名前',
    page: 'ページ',
    target: '対象',
    totalUse: '利用合計',
    checked: 'チェックをつけた',
    remarksContentSearch: '備考内容検索',
    numOfPersons: '人数',
    unaccompanied: '非添い寝',
    accompanied: '添い寝',
    occupation: '職業',
    gender: '性別',
    age: '年齢',
    nationality: '国籍',
    payableDate: '受付日',
    reservation: '予約',
    time: '時刻',
    question: '質問',
    questionOrder: '選択肢',
    questionContent: '質問内容',
    freeText: 'フリーテキスト',
    option: '選択',
    requiredAnswer: '必須回答',
    optionalAnswer: '任意回答',
    author: '作成者',
    checkInDate: 'チェックイン日の',
    start: '開始',
    end: '終了',
    noSet: '設定しない',
    set: '設定する',
    discount: '引き',
    yen: '円',
    overNight: '泊以上',
    untilNight: '泊まで',
    period: '期間',
    months: 'ヶ月',
    item: '項目',
    startPeriod: '期間開始',
    endPeriod: '期間終了',
    date: '日付',
    number: '数',
    credit: 'クレジット',
    point: 'ポイント',
    subItem: '小項目',
    day: '日',
    icon: 'アイコン',
    enable: '有効',
    disable: '無効',
    image: '画像',
    noSmoking: '禁煙',
    smoking: '喫煙',
    roomTypeTitle: 'ペット',
    paymentUnit: '支払単位',
    totalAmount: '総数量',
    stay: '滞在'
  },
  buttons: {
    coppyAndCreate: 'コピーして新規作成',
    detailTicket: 'チケット詳細',
    retainAndAdd: '保存して追加',
    registerLoanItems: '貸出品追加登録',
    registerNewParkingLot: '新規駐車場登録',
    add: '追加',
    login: 'ログイン',
    createNewFacility: '新規部屋タイプ作成',
    eachRoomSettings: '各部屋設定',
    createNewRoomType: '新規部屋タイプ作成',
    createNewRoom: '新規部屋作成',
    titleCreateNewRoom: '部屋新規作成',
    edit: '編集',
    delete: '削除',
    saveAndUpdate: '保存して更新',
    saveAndClose: '保存して閉じる',
    cancel: 'キャンセル',
    saveAndCreate: '保存して作成',
    request: 'リクエスト',
    change: '変更',
    back: 'バック',
    newRoomReservation: '新規宿泊予約',
    approval: '承認',
    remand: '差し戻し',
    close: '閉じる',
    create: '権限を追加',
    save: '保存',
    beforeDailyUpdate: '日次更新前',
    afterDailyUpdate: '日次更新後',
    openCertificates: '利用証・確認証を開く',
    openTheFacilityFloorPlan: '施設間取り図を開く',
    openMemberInformation: '会員情報を開く',
    reservationEdit: '予約編集',
    statementDisplay: '明細書表示',
    changeToCancel: 'キャンセルに変更して明細に移動',
    print: '印刷する',
    splitReceipt: '領収書を分割',
    issuanceRegistration: '発行登録',
    memberAddressLabelPrinting: '会員宛名ラベル印刷',
    printThecertificateOfUse: '利用証印刷',
    returnTheSplit: '分割を戻す',
    createANewPlan: '新規プラン作成',
    register: '登録',
    detail: '詳細',
    createNewInvitation: '新規招待券・利用券作成',
    deleteQuestion: '質問を削除',
    addAQuestion: '質問を追加する',
    deletePlan: 'プランを削除',
    done: '完了',
    startSetting: '設定開始',
    changeAll: 'まとめて変更',
    apply: '適用',
    addFacility: '施設を追加',
    printing: '印刷',
    exportAsCSV: 'CSVで出力',
    CSVOutputDaily: '日報作成用CSV出力',
    CSVOutputLodging: '宿泊集計をCSV出力',
    CSVOutputCancel: 'キャンセル集計をCSV出力',
    CSVOutputNotice: '空室通知集計をCSV出力',
    CSVOutputUsage: '貸出品利用率をCSV出力',
    registerNewUser: '新規ユーザー登録',
    reissuePassword: 'パスワード再発行',
    next: '次へ',
    roomTypeSetting: '部屋タイプ設定'
  },
  texts: {
    stock: '在庫',
    numberOfWaitingList: 'キャンセル待ち件数',
    vacancyNotification: '空室通知'
  },
  rules: {
    data: {
      code: 'コード',
      name: '名前',
      type: 'タイプ',
      carHeight: '車高',
      loanItem: 'ローンアイテム',
      totalQuantity: '総量',
      email: '電子メール',
      password: 'パスワード',
      bedShareRule: '添い寝のルール'
    },
    emailIsInvalid: 'メールアドレスの形式が不正です',
    uniqueEmail: '既に登録されているメールアドレスです',
    required: '{title}が必要',
    maximumNCharacter: '最大{value}文字である必要があります',
    minimumNCharacter: '{value}文字以上であること',
    minimumN: '最低でも{value}人は必要です。',
    moreThanN: '{value}以上であること',
    invalid: '{title}が有効でない',
    maximumNumberInt32: '2147483647以下である必要があります',
    numberInvalid: '番号が無効です',
    noData: 'データなし',
    isRequired: '必須項目です。',
    isKatakana: '全角カタカナでご入力下さい',
    duplicate: '同名',
    isInvalid: '入力内容に誤りがあります。',
    confirmMail: 'パスワードの確認が一致しません',
    createBookingTooManyGuests: '客室定員を超過しています。',
    includeNCharacters: '{value}文字を含める必要があります'
  },
  toast: {
    checkEmail: 'サインインコードがメールで送信されましたので、ご確認ください。',
    welcomePMS: 'PMSへようこそ',
    passwordChanged: 'パスワードを変更しました。'
  },
  reports: {
    dailyUpdate: '日次更新',
    moneyTotal: '合計金額',
    check: 'チェック',
    titleCheckCard: 'TOP クレジットカード チェック',
    amountOfMoney: '金額',
    chooseMultiplePics: 'ファイルをアップロード(複数)',
    firstConfirmer: '1次確認者',
    secondConfirmer: '2次確認者',
    kindName: '金種名',
    dailyReportDate: '日報日付',
    createdDate: '作成日',
    editApproval: '編集承認',
    status: 'ステータス',
    cashCheck: '現金チェック',
    cashRegister: 'レジ金過不足',
    adjustmentAmount: '調整額',
    reason: '理由',
    dailySalesReport: '直営施設金種別売上日報',
    memoComment: 'メモコメント',
    voucherTitle: '{{voucherName}} チェック',
    onTheDay: '当日',
    primaryConfirmer: '一次確認者',
    secondaryConfirmer: '二次確認者',
    dailyCheck: '日次チェック',
    dialogReportTitle: '客室パネル/日報'
  },
  reservation: {
    reservationID: '予約ID',
    reservationSource: '予約元',
    reservationDate: '予約日',
    showSubsequentReservations: '以降の予約を表示',
    reservationDetailsEdit: '予約詳細編集',
    editDetailedStatement: '明細詳細編集',
    memberInformation: '会員情報',
    userCertificate: '利用証',
    facilityInformation: '施設案内資料',
    pointDocument: 'ポイント残高証明書資料',
    leaflets: 'チラシ等',
    typeOfMailingHope: '郵送希望種類',
    searchAllReservations: '全予約検索'
  },
  contractDetail: {
    basicInformation: '基本情報',
    billingStatement: '請求明細',
    billingStatementFormal: 'ご請求明細書',
    guestDetails: '宿泊者詳細',
    rentalListing: '貸出品',
    parkingPlace: '駐車場',
    operationChangeHistory: '操作変更履歴',
    canceled: '削除',
    fixRoomAllocation: '部屋割を固定する',
    remarkNote: '予約バーに表示させる備考内容',
    accommodationRemarks: '宿泊備考',
    usageContract: '利用契約',
    stayingPlan: '宿泊プラン',
    individualCorporationClassification: '個人法人区分',
    reservationSourceSite: '予約元サイト',
    newReservationsAdded: 'この予約グループに追加で新規予約',
    toothbrushReady: '歯ブラシ準備希望',
    requestForMailingOfUsageCertificate: '利用証郵送希望',
    pointBalanceCertificateDocumentMailingRequest: 'ポイント残高証明書資料郵送希望',
    yukataPreparationHope: '浴衣準備希望',
    requestToMailFacilityInformationMaterials: '施設案内資料郵送希望',
    hopeToMailLeaflets: 'チラシ等郵送希望',
    accommodationRepresentative: '宿泊代表者',
    groupMasterBillingStatement: '請求明細',
    fullCash: '全額現金',
    fullCard: '全額カード',
    multipleOthers: '複数その他',
    numberOfReturnPoints: '返還ポイント数',
    subItemSelection: '小項目選択',
    namesOfOtherProductsForSale: 'その他の販売品の名称',
    selectCancellationOnTheDay: '当日キャンセル選択',
    cancellationRate: 'キャンセル率',
    firstDay: '1日目',
    settingCancellationPolicy: '設定キャンセルポリシー',
    invoiceEdit: '請求明細書編集',
    registrationOptions: '発行登録・登録カード支払い',
    consumptionTax: '内消費税',
    bathTax: '内入湯税',
    accommodationTax: '内宿泊税',
    costOfEquipment: '本体代金',
    amountOfReceipt: '領収書金額',
    noteReceipt: '上記の金額正に受領いたしました',
    companyNameText: '東急バケーションズ軽井沢',
    addressText: '〒389-0102 長野県北佐久郡軽井沢町軽井沢1016-652',
    addFromCalendar: 'カレンダーから入力',
    selectFacility: '施設を選択',
    night: '泊',
    day: '日',
    nonSleepChild: '子供(非添い寝)',
    sleepChild: '子供(添い寝)',
    lodgingRepresentativeYomigana: '宿泊代表者ヨミガナ',
    telephoneNum: '代表者電話番号',
    telephoneNumNonRep: '電話番号',
    emailAddress: 'メールアドレス',
    selectCountry: '国を選択',
    postalCode: '郵便番号',
    address: '代表者住所',
    addParkingReservation: '駐車場予約を追加',
    actualDayOfStay: '実宿泊日',
    selectTheDateOfStay: '宿泊日を選択',
    dateOfBathing: '入湯日',
    dateOfHotelTaxSubject: '宿泊税対象日',
    guestInfomation: '宿泊者情報',
    nameOrMember: '氏名・会員',
    passportOrResidenceImage: 'パスポート・在留資格画像(外国籍のみ)',
    uploadFile: 'ファイルをアップロード',
    numberOfReceiptsIssued: '領収書発行枚数',
    remainingMoney: '残金',
    membershipNumber: '会員番号',
    yourName: '御芳名',
    mr: '様',
    subscriberMembershipInformation: '契約者会員情報',
    periodOfStay: '宿泊期間',
    usageFees: 'ご利用料金',
    exchangeRate: '交換区分',
    lodgingPoints: '宿泊ポイント',
    exchangeSource: '交換元',
    exchangeDestination: '交換先',
    dayPlanCertificate: 'デイプラン利用証',
    flexPointCertificate: 'フレックスポイント利用証',
    primeWeekCertificate: 'プライムウィーク確認証',
    sharingPointCertificate: 'シェアリングポイント利用証',
    tokyuCertificate: '東急バケーションズ交換確認証'
  },
  holidaySetting: {
    year: '年度',
    showPreviousYear: '前年度の祝日を読み込む'
  },
  roomAssignment: {
    period: '期間'
  },
  calendar: {
    period: '期間',
    parkingCars: {
      addParkingReservation: '駐車場予約を追加',
      parkingPlace: '駐車場',
      carHeight: '車高制限',
      parkingLot: '駐車場',
      carNumber: 'ナンバー',
      carColor: 'カラー',
      status: 'ステータス'
    },
    rental: {
      addRentalListing: '貸出品を追加',
      addRentalListingPopup: '貸出品追加',
      rentalListing: '貸出品',
      unitPrice: '貸出単価'
    },
    filter: {
      fees: '表示料金',
      highlighting: '強調表示',
      yenUp: '円以上',
      yenBelow: '円以下'
    }
  },
  booking: {
    dateTime: '日時',
    user: 'ユーザー',
    setting: '設定',
    oldValue: '旧値',
    newValue: '新値',
    deleteReservation: 'この予約を削除する',
    cancelDate: 'キャンセル日',
    totalCancelAmount: 'キャンセル合計額',
    totalCancelPoint: 'キャンセル合計ポイント',
    representativeUnregistered: '代表者未登録'
  },
  modalReservation: {
    title: '新規予約作成',
    btnReservation: '予約',
    btnReservationWithoutID: '顧客IDなしで予約',
    btnReservationBlock: 'ブロック作成',
    btnReservationFacility: 'ポイント提携施設',
    btnReservationList: '顧客ID無・その他予約'

  },
  reservationCalendar: {
    title: '枠と利用契約と人数を入力してください',
    select: '選択中',
    checkIn: 'チェックイン',
    facility: '施設',
    typeRoom: '部屋タイプ',
    datePicker: '箱根強羅',
    mwWeek: 'MWPW週番号',
    emptyRoom: '空室通知件数',
    frameNumber: '枠数表示',
    oldInventoryType: '変更元',
    numberFrames: '枠数',
    newInventoryType: '変更先',
    applicableRoomType: '適用部屋タイプ',
    scopeOfApplication: '適用範囲'
  },
  feesPoints: {
    plans: {
      planID: 'プランID',
      enabledOrDisabled: '有効/無効',
      published: '掲載',
      planName: 'プラン名称',
      remainingPublication: '掲載残り',
      possiblePeriodStart: '可能期間開始',
      endOfPossiblePeriod: '可能期間終了',
      priceSetting: '料金設定',
      consecutiveNightDiscount: '連泊割有無',
      cancelSetting: 'キャンセル設定',
      planStatus: 'プラン有効/無効',
      planSubtitle: 'プランサブタイトル',
      contractToCreateAPlan: 'プランを作成する契約',
      facilitiesAndRoomTypes: '施設・部屋タイプ',
      planImage: 'プラン画像',
      planPrStatement: 'プランPR文',
      uploadPlanImages: 'プラン画像をアップロード(2枚まで)',
      upToTwoImages: '2枚まで !!',
      planNotes: 'プランの注意事項',
      planFeeOrConditionSetting: 'プラン料金・条件設定',
      priceSettingMethod: '料金設定方法',
      priceField: '料金(1室1滞在・1名1滞在)',
      pricePlanCSV: 'プラン料金CSV(1室1泊,1名1泊)',
      paymentMethod: '支払い方法',
      settlementOfAccounts: '手仕舞い設定',
      periodOfStay: '宿泊可能期間',
      memberPagePostingPeriod: '会員ページ掲載期間',
      sellOffSetting: '売止め設定',
      roomRateDiscount: '宿泊料金割引',
      limitedNumOfPeople: '人数限定',
      limitedNumOfNights: '泊数限定',
      roomRateCancellationPolicy: '宿泊料金キャンセルポリシー',
      uploadPlanPriceCSV: 'プラン料金CSVファイル(1室1泊,1名1泊)をアップロード',
      bothAvailable: '両方利用可能',
      localPaymentOnly: '現地払いのみ',
      registeredCardPaymentOnly: '登録済みカード払いのみ',
      dayBefore: '日前',
      reservationTimeNote: '時まで予約受付可能',
      fromTheDateOfBooking: '予約を行う日から',
      displayedUpUntilYesterday: '日先まで表示可能',
      maxNumOfUsersPerDay: '1日の利用上限人数',
      maxNumOfRoomsPerDay: '1日の利用上限室数',
      roomRateDiscountNote: '9泊目以降は9泊目の割引を適用する',
      minNumOfUsers: '最小利用人数',
      maxNumOfUsers: '最大利用人数',
      noContact: '連絡なし'
    },
    CSV: {
      CSVRegistration: 'CSV登録(料金マスタのみ)',
      uploadaCSVFile: 'CSVファイルをアップロード',
      CSVUpdateSchedulel: 'CSV更新予定登録(料金マスタのみ)',
      CSVUpdate: 'CSV更新',
      newCSV: '新規CSV更新予定登録',
      scheduledUpdateDate: '更新日予定日',
      registrant: '登録者',
      registrationDate: '登録日',
      cancelUpdate: '更新キャンセル',
      nonProductCharges: '商品外料金',
      nonProductChargeName: '商品外料金名',
      CSVUpload: 'CSVアップロード',
      feePointCSVUpload: 'CSV更新予定登録(料金マスタのみ)',
      updateDate: '更新日',
      CSVUploadNoFees: '商品外料金CSVアップロード'
    },
    calendar: {
      selectAll: '全選択',
      cancelAll: '全解除'
    }
  },
  invitationsAndCoupons: {
    btnReservationDetails: '予約詳細',
    btnNewReservation: '新規予約',
    btnDontUse: '未使用',
    btUsed: '使用済',
    backToInvitationList: '招待券・利用券一覧に戻る',
    dateOfIssue: '発行日',
    invitationTicketNo: 'チケットNo.',
    ticketIssueNum: '発行 枚数',
    expirationDate: '失効期限',
    reservationStartDate: '予約開始日',
    deadlineForUse: '利用期限',
    reservationPeriod: '予約可能期間',
    sheetsUsedNum: '利用 枚数',
    available: '利用 可能',
    authenticationNum: '認証用番号',
    customerName: '顧客名',
    enabledOrDisable: '有効/無効',
    applicationDate: '申込日',
    exportToCSV: '選択項目をCSV出力',
    exportCsv: 'チケット番号をCSV出力',
    copyAndCreate: 'コピーして新規作成',
    createNewInvitationTitle: '新規招待券・利用券発行',
    reservedPath: '予約経路',
    dayOfWeek: '曜日',
    exclusionDay: '除外日',
    userOrNot: '利用有無',
    nightwear: 'ナイトウェア',
    toothbrush: '歯ブラシ',
    shaving: '髭剃り',
    itemAvailableRent: '貸出品利用可能',
    amountUsed: '利用金額',
    free: '無料',
    fixedFree: '固定料金',
    taxInclude: '(税込)',
    cancelPolicy: 'キャンセルポリシー',
    amountRightReserved: '権利設定額',
    fixedAmountRightSetting: '権利設定定額',
    numSheetUser: '利用枚数',
    numSheetAvailable: '利用可能枚数',
    lastUpdatedBy: '作成者',
    callCenter: 'コールセンター',
    sales: '営業',
    note: '※ 招待券・利用券が未使用の場合は、下記の「削除」より削除することができます。',
    invitationNo: '利用券・招待券 No.',
    numSheetIssued: '発行枚数',
    ticketNo: 'チケットNo.',
    representativeName: '代表者名',
    ticketDetail: 'チケット詳細',
    exportTicketNumToCSV: 'チケット番号をCSV出力',
    editInvitation: '招待券・利用券編集',
    corporateTicketBookingCreation: '法人チケット予約作成'
  },
  weekdays: {
    monday: '月',
    tuesday: '火',
    wednesday: '水',
    thursday: '木',
    friday: '金',
    saturday: '土',
    sunday: '日',
    holidays: '祝'
  },
  frameSetting: {
    calendar: {
      periodFrameRatio: '期間内枠比率'
    },
    systemOuter: {
      systemOuterFrame: 'システム外枠'
    }
  },
  formReport: {
    dayPlanRoomIncome: 'デイプラン客室収入',
    rentalRoomIncome: 'レンタル客室収入',
    usageFeeIncome: '利用料収入',
    miscellaneousIncome: '雑収入',
    miscellaneousIncomeNotTaxable: '雑収入(不課税) \'キャンセル料\'',
    outputTax: '仮受消費税',
    lodgingTax: '宿泊税',
    comparisonPeriodStart: '対比期間開始',
    comparisonPeriodEnd: '対比期間終了',
    operatingRatio: '稼働率',
    utilizationRateComparisons: '対比間稼働率',
    contractNumber: '対比間数',
    roomNumber: '部屋数',
    numOfUsers: '利用人数',
    salesPeriodDeposit: '売上期間入金',
    contrastPeriodDeposit: '対比期間入金',
    accountsReceivableUncheckedOut: '売掛未チェックアウト',
    salesAmount: '売上金額',
    consumptionTax: '消費税',
    taxIncludedSales: '税込売上',
    numOfEvents: '件数',
    comparisonSalesAmount: '対比間売上金額',
    interpersonalconsumptionTax: '対比間消費税',
    salesIncludingTax: '対比間税込売上',
    numberOfComparisons: '対比間件数'
  },
  users: {
    allowCrm: 'CRM担当者',
    crmRep: '営業担当者',
    profileImgUrl: 'アイコン',
    roleName: 'メニュー権限',
    facilityName: '施設権限',
    showDisabledUsers: '無効ユーザーも表示',
    lastLogin: '最終ログイン',
    menuPermissions: 'メニュー権限',
    facilityAuthority: '施設権限',
    editUserInformation: 'ユーザー情報編集',
    authorizationSettings: '権限設定',
    email: 'ログインID',
    editEmail: 'ユーザーID',
    userPermission: 'ユーザー権限名',
    viewOnly: '閲覧可能',
    modified: '修正可能',
    registerNewUser: '新規ユーザー登録',
    id: 'ユーザーID',
    password: 'パスワード',
    confirmPassword: 'パスワードを再度入力してください'
  },
  webPageManage: {
    infoRequest: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      document: '資料請求内容',
      remark: '備考',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成'
    },
    infoRequestDetail: {
      back: '資料請求一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'アンケート',
      interest: '興味のある施設',
      learn: '東急バケーションズを知った経緯'
    },
    booking: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成'
    },
    bookingDetail: {
      back: 'ご来店予約一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'ご来店希望日・ご相談内容',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      content: 'ご相談内容'
    },
    consultation: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成'
    },
    consultationDetail: {
      back: 'オンライン相談一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'ご来店希望日・ご相談内容',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      content: 'ご相談内容'
    },
    experience: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望',
      secondHope: '第二希望',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
      salesRepresentative: '営業担当者',
      numOfTimes: '回数'
    },
    experienceDetail: {
      back: '体験宿泊申込一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: '体験宿泊希望日・施設',
      firstHope: '第一希望',
      secondHope: '第二希望',
      content: 'ご相談内容'
    },
    notice: {
      search: '検索',
      add: '新規お知らせ',
      status: 'ステータス',
      date: '受信日',
      creator: '作成者',
      category: 'カテゴリー',
      title: 'タイトル',
      content: '内容',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成'
    },
    noticeDetail: {
      back: 'お知らせ一覧に戻る',
      date: '作成日：',
      title: 'タイトル',
      checkbox: '重要',
      text: '本文',
      delete: '削除',
      update: '保存',
      push: '掲載'
    },
    facility: {
      HPFacilityInformationScreen: 'HP施設情報画面',
      memberPageFacilitySetting: '会員ページ施設設定',
      displayFacilityName: '表示施設名',
      facilityNameAbbreviation: '施設名(略称)',
      latitude: '緯度',
      longitude: '経度',
      addressAbbreviation: '住所(略称)',
      descriptionText: '説明文章',
      remarkSubject: '備考科目',
      otherRemarks: 'その他備考',
      searchScreenDescription: '検索画面説明',
      searchTags: '検索タグ',
      mainImage: 'メイン画像',
      remarkRoomType: '部屋タイプ別予約時備考',
      otherMemos: 'その他メモなど(会員ページ非表示)',
      otherNotes: 'その他メモ'
    },
    routeName: {
      info: 'WEBページ管理_資料請求',
      infoDetail: 'WEBページ管理_資料請求_今津康平',
      booking: 'WEBページ管理_来店予約',
      bookingDetail: 'WEBページ管理_来店予約_今津康平',
      consultation: 'WEBページ管理_オンライン相談',
      consultationDetail: 'WEBページ管理_オンライン相談_今津康平',
      notice: 'WEBページ管理_お知らせ',
      noticeDetail: 'WEBページ管理_お知らせ_新型コロナウイルス感染',
      experience: 'WEBページ管理_体験宿泊',
      experienceDetail: 'WEBページ管理_体験宿泊_今津康平',
      customer: '東急太郎',
      facilitySettings: 'WEBページ管理_施設設定'
    }
  },
  facility: {
    settings: {
      facilityList: '施設リスト',
      propertyID: '物件ID',
      propertyName: '物件名',
      facilityEditing: '施設(貸出品・駐車場)編集',
      facilityType: '施設タイプ',
      facilityTypeNote: 'ポイント提携施設タイプには、',
      facilityTypeNoteDetail: '在庫管理・会員ページ・経理計算・貸出品・駐車場機能がありません。',
      facilityNameManagement: '施設名(管理用)',
      managementScreenDisplayOrder: '管理画面表示順',
      delFacility: 'この施設を削除',
      deleteNotice: 'この施設の情報が利用されていない場合、以下のボタンから削除できます。部屋タイプなどの設定を行った場合は、保守対応での削除となります。',
      facilityFloorPlanPDF: '施設間取り図PDF',
      facilityIDForRakutsu: 'らく通連携用施設ID',
      askAgeOfBedSharedChildren: '添い寝の年齢を聞く',
      receiptDisplayFax: '領収書表示用FAX(半角)',
      receiptDisplayTel: '領収書表示用TEL(半角)',
      receiptDisplayAddress: '領収書表示用住所',
      forReceiptDisplay: '領収書表示用〒(半角)',
      rentalUnitPriceTaxInclude: '貸出単価(税込)',
      rentalUnitPriceTaxExclude: '貸出単価(税抜き)',
      goGame: '囲碁',
      shogi: '将棋',
      othello: 'オセロ',
      playStation: 'プレステ',
      mahjong: '麻雀セット',
      lifeGame: '人生ゲーム',
      roomType: '施設リスト',
      roomTypeDetail: '京都',
      roomTypeID: '部屋タイプID',
      roomTypeName: '部屋タイプ名',
      roomTypeEditing: '部屋タイプ設定',
      roomID: '部屋ID',
      roomName: '部屋名',
      roomEditting: '部屋編集',
      vehicleHeightLimit: '車高制限',
      waitingList: 'キャンセル待ち',
      parkingLotRegistration: '駐車場追加登録',
      roomTypeInformation: '部屋タイプ情報',
      deleteThisRoomType: 'この部屋タイプを削除',
      deleteRoomTypeNotice: 'この部屋タイプに予約がない場合、以下のボタンから削除できます。',
      roomTypeNameManagement: '部屋タイプ名(管理用)',
      managementScreenColor: '管理画面色',
      maxNumOfPeople: '最大定員人数',
      numOfCoSleeping: '添い寝可能人数',
      roomTypeIDCollaboration: 'らく通連携用部屋タイプID',
      roomNameManagement: '部屋名(管理用)',
      deleteThisRoom: 'この部屋を削除',
      deleteRoomNotice: 'この部屋に予約がない場合、以下のボタンから削除できます。',
      createNewRoomType: '部屋タイプ新規作成',
      numberOfRoomsCreated: '作成部屋数',
      roomNameInvalid: '部屋名が無効です。'
    },
    productsForSale: {
      listOfItemForSale: '販売品リスト',
      saleItemID: '販売品ID',
      saleItemName: '販売品名',
      saleUnitPrice: '販売単価',
      taxRate: '税率',
      chargeOnStatement: '明細上料金',
      createNewSaleItem: '販売品新規作成',
      changePriceAccommodation: '宿泊明細での価格の変更',
      editSaleItem: '販売品編集',
      itemIsRequired: 'アイテムが必要'
    },
    rentalList: {
      itemID: '貸出品ID',
      itemForRent: '貸出品',
      unitPrice: '貸出単価（税抜）',
      createNewRentItem: '貸出品新規作成',
      updateRentItem: '貸出品編集',
      listItemForRent: '貸出品リスト',
      paymentUnitPrice: '支払単価',
      deleteNotice: 'この貸出品に予約がない場合は、以下のボタンから削除できます。',
      rules: {
        required: 'この項目は必須です。',
        isNumber: '値は整数でなければなりません。',
        isGreateZero: '値はゼロより大きくなければなりません',
        maxValue: '値は255未満である必要があります'
      }
    }
  }
}

export const SALE_ITEM_LIST = `
  query($includeNotForSale: Boolean) {
    saleItemList(includeNotForSale: $includeNotForSale) {
      id
      name
      price
      taxType
      allowPriceOverwrite
      forSale
      priceIncludeTax
    }
  }
`

export const UPDATE_SALE_ITEM = `
mutation($id: Int!, $data: UpdateSaleItemInput!) {
  updateSaleItem(id: $id, data: $data) {
    allowPriceOverwrite
    price
    id 
    name
    forSale
  }
}
`
